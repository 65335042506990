'use client'

import * as React from 'react'
import { CalendarIcon } from '@radix-ui/react-icons'
import { subMonths, format, addMonths } from 'date-fns'
import { DateRange } from 'react-day-picker'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import useOrders from '@/api/hooks/useOrder'
import { Separator } from '@/components/ui/separator'

interface IDatePickerWithRangeProps
  extends React.HTMLAttributes<HTMLDivElement> {
  // handleSelect: (date: DateRange) => void
}
export function DatePickerWithRange({
  className,
  // handleSelect,
}: IDatePickerWithRangeProps) {
  const today = new Date()
  const {
    caseHistoryFilter: { dateRange: date },
    updateStartAndEndDate,
    getCaseHistory,
  } = useOrders()
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)

  const handleDateChange = (_date: any) => {
    if (!_date) return
    const isStartDateChanged = _date.from !== date?.from
    const isEndDateChanged = _date.to !== date?.to
    let __date = _date
    if (isStartDateChanged && !isEndDateChanged) {
      __date = {
        from: _date.from,
        to:
          _date.to && _date.to > addMonths(_date.from!, 3)
            ? addMonths(_date.from!, 3)
            : _date.to,
      }
    } else if (isEndDateChanged && !isStartDateChanged) {
      __date = {
        from: subMonths(_date.to!, 3),
        to: _date.to,
      }
    }

    updateStartAndEndDate(__date)
    // handleSelect(__date)
  }

  const startDateLimit = date?.to ? subMonths(date.to, 3) : undefined

  const handleSubmit = () => {
    getCaseHistory()
    setIsPopoverOpen(false)
  }

  const handleCancel = () => {
    const dateObj = { from: subMonths(today, 3), to: today }
    updateStartAndEndDate(dateObj)
    setTimeout(() => {
      getCaseHistory(dateObj)
    }, 500)
    setIsPopoverOpen(false)
  }

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <Button
            id='date'
            variant={'outline'}
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !date && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className='mr-2 h-4 w-4' />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} -{' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0' align='start'>
          <Calendar
            initialFocus
            mode='range'
            selected={date as any}
            onSelect={handleDateChange}
            numberOfMonths={2}
            disabled={{
              before: startDateLimit, // Disable dates before 3 months from the selected end date
              after: today, // Disable all future dates from today
            }}
          />
          <Separator />
          {/* cancel and apply button */}
          <div className='flex justify-end gap-4 p-2'>
            <Button variant='outline' onClick={handleCancel}>
              Clear
            </Button>
            <Button variant='default' onClick={handleSubmit} className='mr-2'>
              Apply
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
